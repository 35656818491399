import { getOpacidade } from "../../DataHandler/DataHandler.jsx";

// Variáveis necessárias na Legenda do mapa

var RGBcolor;

// Variáveis necessárias para controle de JSON

export function prepareDataIA(IA, separator) {
    return new Promise(resolve => {
        for (let i = 0; i < IA.features.length; i++) {
            for (let j = 0; j < separator.length; j++) {
                if (IA.features[i].properties.ai_object_id === separator[j].ai_object_id) {
                    Object.assign(IA.features[i].properties, separator[j]);
                }
            }
            IA.features[i].properties.visibility = true
            IA.features[i].properties.legend = true
            if (IA.features[i].properties.deteccao_ativa === true) {
                IA.features[i].properties.ai_object_name = IA.features[i].properties.ai_object_name + " - Ativo"
            } else if (IA.features[i].properties.deteccao_ativa === false) {
                IA.features[i].properties.ai_object_name = IA.features[i].properties.ai_object_name + " - Encerrado"
            }
        }
        resolve(IA)
    })
}

var tituloLegenda = "qualidade"
var hexColorJson = "rgb_cor"
const cor = "cor"

export function prepareData(jsonData) {
    for (let i = 0; i < jsonData.features.length; i++) {
        jsonData.features[i].properties.visibility = false
        jsonData.features[i].properties.legend = true
        if (jsonData.features[i].properties[cor] === '1') {
            jsonData.features[i].properties[tituloLegenda] = "Ótimo"
            jsonData.features[i].properties[hexColorJson] = [59, 132, 47]
            jsonData.features[i].properties.idLegenda = 0
        }
        else if (jsonData.features[i].properties[cor] === '2') {
            jsonData.features[i].properties[tituloLegenda] = "Bom"
            jsonData.features[i].properties[hexColorJson] = [136, 208, 123]
            jsonData.features[i].properties.idLegenda = 1
        }
        else if (jsonData.features[i].properties[cor] === '3') {
            jsonData.features[i].properties[tituloLegenda] = "Regular"
            jsonData.features[i].properties[hexColorJson] = [247, 222, 111]
            jsonData.features[i].properties.idLegenda = 2
        }
        else if (jsonData.features[i].properties[cor] === '4') {
            jsonData.features[i].properties[tituloLegenda] = "Ruim"
            jsonData.features[i].properties[hexColorJson] = [254, 161, 158]
            jsonData.features[i].properties.idLegenda = 3
        }
        else if (jsonData.features[i].properties[cor] === '5') {
            jsonData.features[i].properties[tituloLegenda] = "Péssimo"
            jsonData.features[i].properties[hexColorJson] = [127, 49, 47]
            jsonData.features[i].properties.idLegenda = 4
        }
        else {
            jsonData.features[i].properties[tituloLegenda] = "Não percorrido"
            jsonData.features[i].properties[hexColorJson] = [0, 0, 0, 0]
            jsonData.features[i].properties.idLegenda = 5
        }
    }
    return jsonData
}

export function prepareColors(cores, opacidade) {
    const removeSymbol = cores.split('#')
    hexToRgb(removeSymbol[1])
    function hexToRgb(hex) {
        const arrBuff = new ArrayBuffer(4);
        const vw = new DataView(arrBuff);
        vw.setUint32(0, parseInt(hex, 16), false);
        const arrByte = new Uint8Array(arrBuff);
        if (getOpacidade() === false) {
            RGBcolor = [arrByte[1], arrByte[2], arrByte[3]]
        } else {
            if (opacidade !== undefined) {
                RGBcolor = [arrByte[1], arrByte[2], arrByte[3]]
            } else {
                RGBcolor = [arrByte[1], arrByte[2], arrByte[3], 10]
            }
        }
    }
    return RGBcolor
}

export function generateListNames(geojsons) {
    const list = []
    for (let i = 0; i < geojsons.length; i++) {
        const auxList = []
        for (let j = 0; j < geojsons[i].features.length; j++) {
            auxList.push(geojsons[i].features[j].properties.nome.toUpperCase())
        }
        list.push(auxList)
    }
    return list
}

