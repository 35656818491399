/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { AnalyticRequest } from "../..";
import { Alert, Space } from "antd";

const AnalyticModel = ({ categoria, preset }) => {
    const [selectedQualitys, setSelectedQualitys] = useState([]);
    const [rangeValues, setRangeValues] = useState({});
    const [buraco, setBuraco] = useState(0);
    const [recomposicao, setRecomposicao] = useState(0);
    const [fissura, setFissura] = useState(0);
    const [errorVisibility, setErrorVisibility] = useState(false);
    const [warningVisibility, setWarningVisibility] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [warningMsg, setWarningMsg] = useState('Selecione ao menos uma faixa de qualidade e uma quantidade mínima de defeitos.');
    const rangeRefs = useRef([]);
    const qualityColors = {
        'Ótimo': '#3b842f',
        'Bom': '#88d07b',
        'Regular': '#f7de6f',
        'Ruim': '#fea19e',
        'Péssimo': '#7f312f',
    };

    const handleRangeChange = (title, index, value) => {
        setRangeValues(prevValues => ({
            ...prevValues,
            [index]: value
        }));

        switch (title) {
            case "Buraco":
                setBuraco(value);
                break;
            case "Recomposição Asfáltica":
                setRecomposicao(value);
                break;
            case "Fissura":
                setFissura(value);
                break;
            default:
                break;
        }
    };

    const calculatePosition = (index) => {
        const value = rangeValues[index] || 0;
        const maxValue = preset[index][2];
        const rangeWidth = rangeRefs.current[index]?.offsetWidth || 0;
        const percentage = value / maxValue;
        const position = percentage * (rangeWidth - 18);
        return position;
    };

    const toggleColor = (e) => {
        const clickedQualityName = e.target.textContent;
        const isAlreadySelected = selectedQualitys.some(q => q.name === clickedQualityName);

        if (isAlreadySelected) {
            setSelectedQualitys(prevQualitys => prevQualitys.filter(quality => quality.name !== clickedQualityName));
        } else {
            setSelectedQualitys(prevQualitys => [...prevQualitys, { name: clickedQualityName, color: qualityColors[clickedQualityName] }]);
        }
    }

    useEffect(() => {
        const noSelectedQualitys = selectedQualitys.length === 0;
        const allValuesZero = buraco.toString() === '0' && recomposicao.toString() === '0' && fissura.toString() === '0';

        if (noSelectedQualitys && allValuesZero) {
            setWarningMsg("Selecione ao menos uma faixa de qualidade e uma quantidade mínima de defeitos.")
            setWarningVisibility(true)
            setErrorVisibility(false)
        } else if (noSelectedQualitys) {
            setWarningMsg("Selecione ao menos uma faixa de qualidade.")
            setWarningVisibility(true)
            setErrorVisibility(false)
        } else if (allValuesZero) {
            setWarningMsg("Selecione ao menos uma quantidade mínima de defeitos.")
            setWarningVisibility(true)
            setErrorVisibility(false)
        } else {
            setErrorVisibility(false)
            setWarningVisibility(false)
        }
    }, [selectedQualitys, buraco, recomposicao, fissura])

    const analyticMode = () => {
        const noSelectedQualitys = selectedQualitys.length === 0;
        const allValuesZero = buraco.toString() === '0' && recomposicao.toString() === '0' && fissura.toString() === '0';

        if (noSelectedQualitys && allValuesZero) {
            setErrorMsg("Selecione ao menos uma faixa de qualidade e uma quantidade mínima de defeitos.")
            setWarningVisibility(false)
            setErrorVisibility(true)
            setTimeout(() => {
                if (errorVisibility) {
                    setWarningVisibility(true)
                    setErrorVisibility(false)
                }
            }, 3000);
            return
        } else if (noSelectedQualitys) {
            setErrorMsg("Selecione ao menos uma faixa de qualidade.")
            setWarningVisibility(false)
            setErrorVisibility(true)
            setTimeout(() => {
                if (errorVisibility) {
                    setWarningVisibility(true)
                    setErrorVisibility(false)
                }
            }, 3000);
            return
        } else if (allValuesZero) {
            setErrorMsg("Selecione ao menos uma quantidade mínima de defeitos.")
            setWarningVisibility(false)
            setErrorVisibility(true)
            setTimeout(() => {
                if (errorVisibility) {
                    setWarningVisibility(true)
                    setErrorVisibility(false)
                }
            }, 3000);
            return
        }
        AnalyticRequest(selectedQualitys, buraco, recomposicao, fissura, document.getElementById("output").value, categoria)
    }

    return (
        <div className="fontFamilyAnalytic">
            <div className="infoSeparate"></div>
            <Space direction="vertical" style={{ width: '100%', marginBottom: "10px" }}>
                {warningVisibility && (
                    <Alert message={warningMsg} type="warning" showIcon />
                )}
                {errorVisibility && (
                    <Alert message={errorMsg} type="error" showIcon />
                )}
            </Space>
            <div className="rugosidadeChartInfo fontFamilyAnalytic" style={{ fontWeight: "bold", fontSize: '15px' }}>Selecione Faixa(s) de qualidade a serem analisadas</div>
            <div className="QualityGrid">
                {Object.keys(qualityColors).map(quality => (
                    <div
                        key={quality}
                        onClick={toggleColor}
                        style={{ backgroundColor: selectedQualitys.some(q => q.name === quality) ? qualityColors[quality] : '#b9b9b9', fontWeight: selectedQualitys.some(q => q.name === quality) ? 'bold' : '500' }}
                    >
                        {quality}
                    </div>
                ))}
            </div>
            <div className="rugosidadeChartInfo fontFamilyAnalytic" style={{ fontWeight: "bold", fontSize: '15px' }}>Selecione quantidade MÍNIMA de defeitos</div>
            <div className="infoContainer">
                {preset.map(([color, title, maxValue], index) => (
                    <div key={index} className="itemContainer">
                        <div className="colorCircle" style={{ backgroundColor: color }}></div>
                        <div className="titleFilter">{title}</div>
                        <div className="rangeContainer">
                            <input
                                type="range"
                                min="0"
                                max={maxValue}
                                value={rangeValues[index] || 0}
                                onChange={e => handleRangeChange(title, index, e.target.value)}
                                ref={el => rangeRefs.current[index] = el}
                            />
                            <div
                                className="rangeValue"
                                style={{ left: calculatePosition(index) }}
                            >
                                {rangeValues[index] || 0}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="filterAnalyticCenter">
                <div className={`filterAnalyticBtn ${warningVisibility ? 'disabled' : ''}`}
                    onClick={analyticMode}>
                    <i className="fas fa-filter"></i> Filtrar
                </div>
            </div>
        </div >
    )
}

export default AnalyticModel;
