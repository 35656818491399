import React, { useEffect, useState } from "react"
import { ChartModel } from "../.."
import axios from "axios"
import { getModo } from "../Filter/Filter.jsx"

let typeFilter;
export function setTypeFilter(value) {
    typeFilter = value
}

export function ChartRug({ apiSchema }) {
    const [chartRug, setChartRug] = useState([])
    const [labelsRug, setLabelsRug] = useState([])
    const [lastUpdated, setLastUpdated] = useState(null);

    useEffect(() => {
        const handleInputChange = () => {
            setLastUpdated(Date.now());
        };

        const deButtonElem = document.getElementById("deButton");
        const ateButtonElem = document.getElementById("ateButton");

        if (deButtonElem) {
            deButtonElem.addEventListener('change', handleInputChange);
        }

        if (ateButtonElem) {
            ateButtonElem.addEventListener('change', handleInputChange);
        }

        return () => {
            if (deButtonElem) {
                deButtonElem.removeEventListener('change', handleInputChange);
            }
            if (ateButtonElem) {
                ateButtonElem.removeEventListener('change', handleInputChange);
            }
        };
    }, []);

    useEffect(() => {
        setLastUpdated(Date.now());
    }, [typeFilter]);

    useEffect(() => {
        const dadoFiltro = (document.getElementById("typeFilter").value === "") ? null : document.getElementById("typeFilter").value
        const minExt = (document.getElementById("deButton").value === "") ? null : document.getElementById("deButton").value.replace(",", ".")
        const maxExt = (document.getElementById("ateButton").value === "") ? null : document.getElementById("ateButton").value.replace(",", ".")
        const API = apiSchema[0] + apiSchema[1] + getModo() + apiSchema[2] + dadoFiltro + apiSchema[3] + minExt + apiSchema[4] + maxExt
        // console.log(apiSchema[0] + apiSchema[1] + getModo() + apiSchema[2] + dadoFiltro + apiSchema[3] + minExt + apiSchema[4] + maxExt)
        function fetchData() {
            axios.get(API)
                .then(response => {
                    // console.log('response.data', response.data);
                    setChartRug(response.data.datas.map(subArray =>
                        subArray.map(value => parseFloat(value))
                    ))
                    setLabelsRug(response.data.labels)
                })
                .catch(error => {
                    console.error('Error:', error);
                })
        }

        fetchData();
    }, [lastUpdated])

    return (
        <div className="chart-container" >
            <ChartModel
                mode="bar"
                labels={labelsRug}
                datas={chartRug}
                colors={['#3b842f', '#88d07b', '#f7de6f', '#fea19e', '#7f312f']}
                responsive={true}
                stacked={true}
                legend={false}
                counter="m"
            />
        </div>
    )
} 