/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

export function PopupInfo(props) {
    const images = props.images.map((item, index) => ({ src: item.url, index: index }));
    if (images.length !== 0) { document.getElementById("dateInfoAux").textContent = props.images[0].detection_date }
    var html = []
    const object = props.selected.object.properties
    const container = document.getElementsByClassName('canvas-container')
    const upper = document.getElementsByClassName('upper-canvas ')
    if (container.length !== 0) {
        for (let i = 0; i < upper.length; i++) {
            upper[i].style.height = "50px"
        }
        for (let i = 0; i < container.length; i++) {
            container[i].style.height = "50px"
        }
    }
    const changeDateInfo = () => {
        setTimeout(() => {
            let element = parseInt(document.querySelectorAll(".__JnHV")[0].textContent.split(" / ")[0])
            document.getElementById("dateInfoAux").textContent = props.images[element - 1].detection_date
        }, 100);
    }

    if (object.file_name) {
        var infos = []
        for (let key in object) {
            if (!(
                key === "mudar aqui" || key === "ai_object_name" || key === "bounding_box_x" || key === "bounding_box_y" || key === "bounding_box_width" || key === "bounding_box_height" ||
                key === "cor" || key === "pk" || key === "id_trecho" || key === "idclasseviaria" || key === "iddistrito" || key === "idsubpref" || key === "idzona" || key === "ai_object_id" || key === "file_name" || key === "visibility" || key === "legend" || key === "RGBconverted" || key === "cartodb_id" || key === "numero" || key === "cidade" || key === "enderec_1" || key === "pagamento_1")) {
                if (key === "Número OS" && object[key] === "") {
                    object[key] = "NDA"
                }
                else if (key === "Número OS" && object[key] !== "") {
                    object[key] = object[key].split('.')[0]
                }
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                infos.push(<h6 key={key} style={{ fontSize: "13px", lineHeight: 0.5, margin: "0", padding: "5", paddingTop: "10px" }}>{key}: <a style={{ fontWeight: "300", marginLeft: "5px", userSelect: "all" }}> {object[key]}</a></h6>)
            }
        }

        html =
            <div key={1} className="popupFormat">
                <div id="zoomThumb" className="img-zoom-result"></div>
                <div id="myCarousel" className="carousel slide" data-ride="carousel" style={{ height: "100%" }}>
                    <div className="carousel-inner" style={{ margin: 0, padding: 0 }}>
                        <div className="item" key="0">
                            <div className="popupMolde">
                                <div className="expandImageButton" role="button" tabIndex={0}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            document.getElementById("fundoExpandedImage").style.opacity = 1
                                            document.getElementById("fundoExpandedImage").style.pointerEvents = "all"
                                        }
                                    }}
                                    onClick={() => {
                                        document.getElementById("fundoExpandedImage").style.opacity = 1
                                        document.getElementById("fundoExpandedImage").style.pointerEvents = "all"
                                    }}
                                >
                                    <i className="fas fa-expand"></i>
                                </div>
                            </div>
                            <Carousel
                                images={images}
                                thumbnailWidth={'30%'}
                                hasSizeButton={false}
                                onIndexChange={changeDateInfo}
                                style={{ maxWidth: '25vw', maxHeight: '25vw' }}
                            />
                        </div>
                    </div>

                    <div>
                        <a className="left carousel-control" href="#myCarousel" data-slide="prev">
                            <span className="glyphicon glyphicon-chevron-left" style={{ fontSize: "20px" }}></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="right carousel-control" href="#myCarousel" data-slide="next">
                            <span className="glyphicon glyphicon-chevron-right" style={{ fontSize: "20px" }}></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <p></p>
                <div className="popupFormatInfo">
                    <div className="titleFormat">
                        <div>
                            #
                        </div>
                        <div className="titleInfo">
                            {object.ai_object_name.toUpperCase().split(" - ")[0]}
                            <div id="dateInfoAux" className="dateInfo"> </div>
                        </div>
                    </div>
                    <div className="contentFormat">
                        <p>Última Detecção: {object.last_detection_date}</p>
                        {/* <p>Classe: {object.object_type}</p> */}
                        <p>Endereço: {object.nomeLogradouro}</p>
                        <p>ID Detecção: {object.pk}</p>
                        {object.ai_object_name.split(" - ")[1] !== undefined ? <p>Status: {object.ai_object_name.split(" - ")[1]}</p> : <p></p>}
                        {object.ai_object_name.split(" - ")[1] === "Ativo" ? <div className="centerConditionElement"><div className="conditionElement ativa">OCORRÊNCIA ATIVA</div></div> : <div></div>}
                        {object.ai_object_name.split(" - ")[1] === "Encerrado" ? <div className="centerConditionElement"><div className="conditionElement encerrada">OCORRÊNCIA ENCERRADA</div></div> : <div></div>}
                    </div>
                    {/* {infos} */}
                </div>
            </div>
    } else if (object.analise) {
        const excludedKeys = ["cor_rgb", "cor", "analise", "visibility"];
        const p = []
        for (const key in object) {
            // eslint-disable-next-line no-prototype-builtins
            if (!excludedKeys.includes(key)) {
                p.push(<p key={key}>{key + ': '}{object[key]}</p>)
            }
        }
        html = <div className="contentFormat">
            {p}
        </div>
    } else {
        html = <div key={1}
            style={{ textAlign: "center", fontSize: "12px", pointerEvents: "none" }}
        >{props.selected.object.properties[props.popupMode].toUpperCase()}
        </div>
    }

    return (
        <>
            {html}
        </>
    )
}
